.App {
  text-align: center;
}
body {
  overflow-x: hidden; /* Hide vertical scrollbar */
}

.sticky-header {
  position: sticky !important;
  top: 0 !important;
  background-color: 'white';
  z-index: 100;
  /* Add other styles as needed */
}
.sticky {
  background-color: 'white'; /* Change background color when sticky */
  z-index: 100;
}

.titles {
  font-family: "titles";
}
/*this is where we regulate fonts */
.subtitle-text {
  font-size: 1.3rem; /* Default font size */
  font-family: "titles";
}

@media (max-width: 768px) {
  .subtitle-text {
    font-size: 14px; /* Font size for screens up to 768px width */
  }
}

@media (max-width: 480px) {
  .subtitle-text {
    font-size: 12px; /* Font size for screens up to 480px width (mobile devices) */
  }
}


.title-text {
  font-size: 2rem; /* Default font size */
  font-family: "titles";
}

@media (max-width: 768px) {
  .title-text {
    font-size: 18px; /* Font size for screens up to 768px width */
  }
}

@media (max-width: 480px) {
  .title-text{
    font-size: 16px; /* Font size for screens up to 480px width (mobile devices) */
  }
}


.course-title-text {
  font-size: 2rem; /* Default font size */
  font-family: "titles";
}

@media (max-width: 768px) {
  .course-title-text {
    font-size: 20px; /* Font size for screens up to 768px width */
  }
}

@media (max-width: 480px) {
  .course-title-text{
    font-size: 18px; /* Font size for screens up to 480px width (mobile devices) */
  }
}

.cover-div {
  height: 560px
}
@media (max-width: 768px) {
  .cover-div {
    height: 440px /* Font size for screens up to 768px width */
  }
}

@media (max-width: 480px) {
  .cover-div{
    height: 380px /* Font size for screens up to 480px width (mobile devices) */
  }
}

.partner-div{
  width:20%
}
@media (max-width: 768px) {
  .partner-div {
    width:48% /* Font size for screens up to 768px width */
  }
}

@media (max-width: 480px) {
  .partner-div{
    width:48% /* Font size for screens up to 480px width (mobile devices) */
  }
}

.footer-title {
  font-size: 2rem; /* Default font size */
  font-family: "titles";
}

@media (max-width: 768px) {
  .footer-title {
    font-size: 18px; /* Font size for screens up to 768px width */
  }
}

@media (max-width: 480px) {
  .footer-title {
    font-size: 16px; /* Font size for screens up to 480px width (mobile devices) */
  }
}


/*footer styling*/

.footer_grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.footer_item{
  width: 25%;
  background-color: lightgray;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
}

@media (max-width: 768px) {
  .footer_item {
    width: 50%;
  }
}

/*image mobile styling*/
.folding-image{
  visibility:visible;
}
@media (max-width: 768px) {
  .folding-image{
    visibility: hidden;
    width: 0px !important;
  }
}
.column-layout {
  flex-direction: column;
}

.row-layout {
  flex-direction: row;
}

@media (max-width: 767px) { /* Change breakpoint as needed */
  .container {
    flex-direction: column;
  }
}
.box:nth-child(2) {
  order: -1; /* Rises to the top */
}

.backdrop-image1{
 background-image: none;
}
@media (max-width: 768px) {
  .backdrop-image{
    background-image:none;
  }
}
.backdrop-image2{
  background-image: none;
 }
 @media (max-width: 768px) {
   .backdrop-image{
     background-image:none;
   }
 }



.flag {
  font-size: 24px;
}

.slick-slide img {
  display: inherit !important;
}

.slick-next {
  right: -15px !important;
}

.slick-prev {
  left: -15px !important;
}

.list {
  justify-content:center;
  padding:10%;
  display:flex;
  flex-wrap: wrap;
  overflow-x: scroll;
  overscroll-behavior: contain;
  scrollbar-width: none;
  -ms-overflow-style: none;
  /* momentum scroll on iOS */
  -webkit-overflow-scrolling: touch;
  /* disable vertical scroll for touch */
  touch-action: pan-x;
  /* scroll snapping */
  scroll-snap-type: x mandatory;
}

.list::-webkit-scrollbar {
  display: none;
}

@media (max-width: 479px) { 
  .officePic {
    max-width: 90vW !important;
  }
  .google-map{
    height: 35VH !important;
  }
}


.indicatorsList,
.list {
  list-style: none;
  padding: 0;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}

.item {
  max-height: 70VH;
  width: 100%;
  flex-shrink: 0;

  /* scroll snapping */
  scroll-snap-align: start;
  scroll-snap-stop: always;
}

html { /* body won't work ¯\_(ツ)_/¯ */
  scroll-snap-type: y mandatory;
}

.item {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-shadow: 0 2px 1px rgba(0, 0, 0, 0.4);
}

.slick-active {
  color:#c8cac0;
}

.slick-dots li button:before {
  color:#c8cac0 !important;
  font-size: 12px !important;
}

.text {
  font-family: "text";
}
.menu {
  font-family: menu;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.pin-icon{
   color:#d67729;
}

.pin-text {
  color:#d67729;
  font-family: "text";
  font-size: 1.2rem;
  text-shadow: 2px 2px black;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: "menu";   /*Can be any text*/
  src: local("CaveatBrush-Regular"),
    url("./fonts/CaveatBrush-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "titles";   /*Can be any text*/
  src: local("Montserrat-Bold"),
    url("./fonts/Montserrat-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "text";   /*Can be any text*/
  src: local("Segoe-UI"),
    url("./fonts/Segoe-UI.ttf") format("truetype");
}


